/* Testimonial Section Styling */
.testimonials {
    background-color: #07252d;
    color: #e0c59d;
    padding: 60px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Georgia', serif;
    position: relative; /* Required for onion positioning */
}

.testimonial-container {
    max-width: 800px;
    text-align: center;
    position: relative; /* For sliding effect */
    overflow: hidden; /* Hide content during slide transition */
    min-height: 325px; /* Set a min-height to prevent resizing */
    margin-bottom: 10vh;

}

.testimonial-title {
    font-size: 48px;
    font-weight: normal; /* Remove bold styling */
    margin-bottom: 30px;
    color: #ead8be;
    font-family: 'Playfair Display', serif; /* Different font for a classy touch */
}

.testimonial-quote {
    font-size: 24px;
    font-style: italic;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #fff; /* White for the quote */
}

.testimonial-author {
    font-size: 18px;
    font-weight: normal;
    color: #e0c59d; /* Light gold for the author */
}

/* Slide-in from right animation */
.slide-in-right {
    transform: translateX(100%);
    opacity: 0;
    animation: slideIn 1s forwards;
}

/* Slide-out to left animation */
.slide-out-left {
    transform: translateX(0);
    opacity: 1;
    animation: slideOut 1s forwards;
}

@keyframes slideIn {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideOut {
    0% {
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        transform: translateX(-100%);
        opacity: 0;
    }
}

/* Onion Illustration Styling */
.onion-illustration {
    position: absolute;
    left: 25vw; /* Position on the left */
    top: -30vh; /* Position towards the top */
    width: 150px; /* Set width */
    opacity: 0.8; /* Subtle opacity effect */
    pointer-events: none; /* Prevent interaction with the image */
    transition: transform 0.1s ease-out; /* Smooth movement */
}
