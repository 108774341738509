/* Hero Section Styling */
.hero {
  position: relative;
  width: 100%;
  height: 100vh; /* Hero takes up 80% of the viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Ensures the image doesn't overflow */
}

/* Hero Image Styling */
.hero img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Image covers the full width */
  height: 140%; /* Increased height to make the image more zoomed out */
  object-fit: cover; /* Ensures the image covers the entire section */
  object-position: center; /* Center the image */
  z-index: -1; /* Place the image behind the text */
}

/* Hero Text Styling */
.hero-text {
  position: relative;
  color: #fff; /* Brighter text color */
  font-size: 64px; /* Large text */
  font-family: 'Playfair Display', serif; /* Classy font style */
  font-weight: normal;
  text-align: center;
  z-index: 1; /* Ensure the text stays above the image */
  text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.8); /* Add a glow effect */
  line-height: 1.05; /* Closer spacing between the text lines */
}

/* Glow Effect */
.hero-text h1 {
  margin: 0; /* Remove default margin for even closer spacing */
}

@media (max-width: 900) {
  .hero img {
    width: 100%; /* Zoom out even more on smaller screens */
    height: 150%; /* Adjust accordingly */
  }
} 

@media (max-width: 768px) {
  .hero {
    margin-top: 7vh;
    height: 60vh; /* Adjust height for smaller screens */
  }

  .hero-text {
    font-size: 36px; /* Smaller text for mobile screens */
  }

  .hero img {
    margin-top: 0vh;
    width: 300vw; /* Zoom out even more on smaller screens */
    height:auto; /* Adjust accordingly */
  }


}
