/* Global fix to remove horizontal scroll */
body, html {
  overflow-x: hidden;
}

/* Main Menu Page Styling */
.menu-page {
  background-color: #07252d; /* Dark blue background */
  color: #ead8be; /* Light gold color */
  padding: 60px 20px;
  position: relative;
  overflow: hidden; /* Ensures background images don't overflow */
}

/* Menu Title Styling */
.menu-title {
  font-family: 'Playfair Display', serif; /* Consistent with theme */
  font-size: 48px;
  text-align: center;
  margin-bottom: 40px;
  color: #ead8be;
}

/* Menu Section Title */
.menu-section-title {
  font-size: 36px;
  color: #ead8be;
  margin-bottom: 20px;
  text-align: center;
}

/* Menu Items Container */
.menu-items {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 items per row on large screens */
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #0b333e; /* Slightly darker blue for contrast */
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  height: 400px; /* Ensures all boxes are the same height */
  z-index: 1;
}

/* Hide scrollbar in WebKit browsers (Chrome, Safari, etc.) */
.menu-item p::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

.menu-item-image {
  width: 100%;
  height: 200px; /* Set a fixed height for all images */
  border-radius: 12px;
  object-fit: cover; /* Ensure the image covers the set height and width while cropping */
  flex-shrink: 0; /* Prevent image from shrinking */
  margin-bottom: 10px;
}

.menu-item-text {
  flex-grow: 1; /* Make the text container grow */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.menu-item h4 {
  font-size: 22px;
  margin-bottom: 10px;
  font-family: 'Playfair Display', serif;
}

.menu-price {
  font-weight: normal;
  font-size: 18px;
  color: #ead8be;
  margin-top: 5px;
}

.menu-item p {
  font-size: 16px;
  color: #ead8be;
  line-height: 1.6;
  margin-top: 10px;
  max-height: 60px; /* Adjust height for description */
  overflow: hidden; /* Prevent description from overflowing */
  text-overflow: ellipsis;
  display: block;
  padding: 0 10px; /* Add padding for spacing */
  overflow-y: auto; /* Enable vertical scrolling */
}

/* Parallax Background Images */
.parallax-image {
  position: absolute;
  opacity: 0.3;
  pointer-events: none;
  z-index: 1; /* Behind content */
}


.onion { left: 10%; top: 30%; width: 150px; }
.grapefruit { right: 10%; top: 20%; width: 250px; }
.rosemary { left: 2%; top: 2%; width: 450px; }
.green-onion { right: 15%; bottom: 10%; width: 150px; }
.clam { left: 30%; top: 5%; width: 150px; }
.berry { right: 10%; top: 10%; width: 120px; }

/* Second set of images (lower part) */
.onion-lower { left: 10%; bottom: 0%; width: 150px; }
.grapefruit-lower { right: 10%; bottom: 0%; width: 250px; }
.rosemary-lower { left: 20%; bottom: 0%; width: 150px; }
.green-onion-lower { left: 10%; bottom: 50%; width: 150px; }
.clam-lower { left: 50%; bottom: 33%; width: 150px; }
.berry-lower { right: 25%; bottom: 28%; width: 200px; }

/* Responsive Design */
@media (max-width: 1440px) {
  .menu-items {
    grid-template-columns: repeat(3, 1fr); /* 3 items per row on MacBook-like screens */
  }
}

@media (max-width: 768px) {
  .menu-items {
    grid-template-columns: 1fr; /* Single column layout on mobile */
  }

  .menu-title {
    font-size: 36px;
  }

  .parallax-image {
    width: 100px;
  }
}
