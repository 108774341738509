/* Main About Section Styling */
.about-section {
  background-color: #07252d;
  color: #ead8be;
  padding: 60px 0;
  font-family: 'Georgia', serif;
  position: relative; /* For positioning illustrations */
  overflow: hidden; /* Ensure illustrations don't overflow */
}

/* About Content Container */
.about-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Align at the top for the title and text */
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

/* Text Container Styling */
.text-container {
  flex: 1;
  padding-right: 40px;
}

.text-container h2 {
  font-size: 48px;
  color: #ead8be;
  font-family: 'Playfair Display', serif; /* Different font for a classy touch */
  font-weight: normal; /* Remove bold styling */
  display: inline-block;
  margin-right: 20px; /* Adjust space between text and lines */
  position: relative;
  white-space: nowrap; /* Prevents text from breaking */
}

/* Two lines to the right of the heading */
.about-lines {
  display: flex;
  flex-direction: column; /* Stack the lines vertically */
  justify-content: center;
  margin-left: 3px; /* Small space between text and lines */
  margin-top: -3vh;
}

.line {
  width: 80px;
  height: 1px;
  background-color: #ead8be;
  margin-bottom: 5px; /* Space between the two lines */
}

/* Text Description Styling */
.text-container p {
  font-size: 18px;
  line-height: 1.8;
  color: #ead8be;
  margin-top: 10px; /* Add some space between the title/lines and the text */
}

/* Image Container Styling */
.image-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.image-container img {
  width: 100%;
  height: auto;
  margin-right: 0vw;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

/* Clam Illustration with Parallax Effect */
.clam-illustration {
  position: absolute;
  left: 5vw; /* Position on the left */
  bottom: 10vh; /* Position it higher */
  width: 150px; /* Adjust width as needed */
  opacity: 0.8;
  pointer-events: none; /* Prevent interaction with the image */
  transform: translateY(0); /* Default position */
  transition: transform 1s ease-out; /* Smooth movement with 1s duration */
}

/* Berry Illustration with Parallax Effect */
.berry-illustration {
  position: absolute;
  right: 5vw; /* Position on the right */
  bottom:  50vh; /* Position it higher */
  width: 300px; /* Adjust width as needed */
  opacity: 0.8;
  pointer-events: none; /* Prevent interaction with the image */
  transform: translateY(0); /* Default position */
  transition: transform 1s ease-out; /* Smooth movement with 1s duration */
  z-index: 0;;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .about-content {
    flex-direction: column;
    text-align: center;
  }

  .text-container {
    padding-right: 0;
    margin-bottom: 20px;
  }

  .image-container {
    justify-content: center;
  }

  .image-container img {
    width: 100%;
  }

  /* Align lines under the heading on small screens */
  .about-lines {
    margin-left: 0;
    flex-direction: row; /* Stack lines horizontally on small screens */
    margin-top: 0px;
  }

  .line {
    margin-bottom: 0;
    margin-left: 10px;
    width: 50px;
  }

  /* Adjust positioning for clam and berry on smaller screens */
  .clam-illustration, .berry-illustration {
    width: 100px;
    bottom: 5vh;
  }
}
