/* DoorDash Section Styling */
.doordash-section {
  padding: 15vh 0;
  text-align: center;
  background-color: #07252d; /* Match the dark blue background */
  color: #ead8be; /* Light gold text color to match the theme */
  font-family: 'Georgia', serif; /* Keep font consistent with the site */
  position: relative; /* For positioning the illustrations */
  overflow: hidden; /* Ensure the images don't overflow */
}

/* Heading Styling */
.doordash-section h2 {
  font-size: 48px;
  margin-bottom: 20px;
  color: #ead8be; /* Light gold for the heading */
  font-family: 'Playfair Display', serif; /* Use classy font for headings */
  font-weight: normal; /* No bold styling */
}

/* Button Styling */
.doordash-section button {
  padding: 15px 30px;
  font-size: 18px;
  background-color: #ff4500; /* CTA contrast color */
  color: #fff;
  border: none;
  border-radius: 50px; /* Rounded corners */
  cursor: pointer;
  font-family: 'Georgia', serif; /* Keep button font consistent */
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out; /* Smooth transitions */
}

/* Button Hover Effect */
.doordash-section button:hover {
  background-color: #e03d00; /* Slightly darker shade on hover */
  transform: scale(1.05); /* Slight zoom effect on hover */
}

/* Flower Illustration Styling */
.flower-illustration {
  position: absolute;
  right: 10vw; /* Position it to the right */
  bottom: 0vh; /* Slightly off-screen */
  width: 200px; /* Set the size */
  opacity: 0.6; /* Subtle opacity for effect */
  pointer-events: none; /* Prevent interaction with the image */
  transition: transform 0.5s ease-in-out; /* Smooth movement */
}

/* Green Onion Illustration Styling */
.green-onion-illustration {
  position: absolute;
  left: 5vw; /* Position it to the left */
  bottom: 15vh; /* Slightly off-screen */
  width: 250px; /* Set the size */
  opacity: 0.6; /* Subtle opacity for effect */
  pointer-events: none; /* Prevent interaction with the image */
  transition: transform 1s ease-in-out; /* Smooth movement */
}

.button-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
}

.doordash-button,
.chownow-button {
  padding: 15px 30px;
  font-size: 18px;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-family: 'Georgia', serif;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.doordash-button {
  background-color: #ff4500;
}

.doordash-button:hover {
  background-color: #e03d00;
  transform: scale(1.05);
}

.chownow-button {
  background-color: #2b9348;
}

.chownow-button:hover {
  background-color: #207a38;
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .button-container {
    flex-direction: column;
    align-items: center;
  }

  .doordash-button,
  .chownow-button {
    width: 100%;
    max-width: 300px;
  }
}

@media (max-width: 768px) {
  .flower-illustration,
  .green-onion-illustration {
    width: 150px; /* Adjust size for smaller screens */
  }

  .flower-illustration {
    right: 10vw; /* Adjust position for smaller screens */
    bottom: -5vh;
  }

  .green-onion-illustration {
    left: 10vw; /* Adjust position for smaller screens */
    top: 10vh;

  }
}
