/* Location Section Styling */
.location-section {
    padding: 40px;
    text-align: center;
    background-color: #07252d; /* Matches the dark blue background */
    color: #e0c59d; /* Light gold color to match the theme */
    font-family: 'Georgia', serif; /* Consistent font */
    position: relative; /* Required for the grapefruit positioning */
    overflow: hidden; /* Ensure the image doesn't overflow */
}

/* Heading Styling */
.location-section h2 {
    font-size: 48px;
    color: #ead8be;
    font-family: 'Playfair Display', serif; /* Different font for a classy touch */
    font-weight: normal; /* Remove bold styling */
    display: inline-block;
    margin-right: 20px; /* Adjust space between text and lines */
    position: relative;
    white-space: nowrap; /* Prevents text from breaking */
}

/* Google Maps Container Styling */
.map-container {
    width: 100%;
    max-width: 800px; /* Limit the width of the map */
    height: 400px;
    margin: 20px auto; /* Center the map container */
    margin-top: 0vh;
    z-index: 2; /* Ensure the map goes over illustrations */
    position: relative; /* Needed for z-index to work */
}

.location-section iframe {
    width: 100%;
    height: 100%;
    border: none;
    z-index: 2; /* Ensure the map iframe goes over illustrations */
    position: relative; /* Needed for z-index to work */
}

/* Paragraph Styling */
.location-section p {
    font-size: 18px;
    margin-top: 20px;
    color: #e0c59d; /* Light gold for text */
}

/* Hover effect for heading */
.location-section h2:hover {
    color: #fff; /* White on hover */
    transition: color 0.3s ease-in-out;
}

/* Grapefruit Illustration */
.grapefruit {
    position: absolute;
    right: 4vw; /* Move it to the left */
    margin-top: 30vh; /* Bring it higher */
    width: 200px; /* Slightly increase width */
    opacity: 0.8; /* Subtle opacity effect */
    pointer-events: none; /* Prevent interaction with the image */
    transition: transform 0.1s ease-out; /* Smooth movement */
    z-index: 0; /* Lower z-index than map */
}

/* Rosemary Illustration */
.mary {
    position: absolute;
    left: 0vw; /* Place it on the left */
    top: -15vh; /* Place it near the top */
    height: auto; /* Maintain aspect ratio */
    width: 17vw; /* Set the size of the rosemary */
    opacity: 0.8; /* Subtle opacity effect */
    pointer-events: none; /* Prevent interaction with the image */
    display: block; /* Ensure the element is displayed */
    z-index: 0; /* Lower z-index than map */
}

@media (max-width: 400px) {
    .grapefruit {
      right: 10vw; /* Adjust position for smaller screens */
      bottom: -5vh;
    }
  
    .mary {
      width: 15vh;
      bottom: 5vh;
  
    }
  }